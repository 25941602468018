#loginpage {
  ion-list {
    border-radius: 10px;
    max-width: 700px;
    margin: 0 auto;
    box-shadow: 1px 10px 50px #ddd;
    min-height: 70vh;
    margin-bottom: 50px;
    padding-bottom: 50px !important;
  }
}

#mainframe {
  iframe {
    float: left;
    height: 100vh;
    width: 400px;
  }
  #toggle-log {
    position: absolute;
    bottom: 5px;
    margin: 5px;
    z-index: 100;
    background-color: white;
    color: gray;
  }
}

#taxameter {
  z-index: 100;
  padding: 3px;
  padding-right: 6px;
  // border-radius: 5px;
  position: absolute;
  bottom: 1px;
  left: 0px;
  cursor: pointer;
  background-color: rgba(60, 60, 60, 0.7);
  color: white;
}

.mobile {
  #taxameter {
    top: 30px !important;
    bottom: unset;
  }
}

.billing-on {
  // border: solid 3px rgb(129, 164, 228);
  background-color: white;
}

.billing-off {
  // border: solid 3px gray;
  background-color: gray;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.oneline {
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

$desktop-main-width: 900px;
@media only screen and (max-width: $desktop-main-width) {
  .desktop-only {
    display: none;
  }
}

#fullscreen-toggle {
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 1000;
  color: white;
  zoom: 1.6;
  cursor: pointer;
}

.hidden {
  display: none;
}

.no-padding {
  padding: 0px !important;
}

ion-item {
  ion-label {
    font-size: 18px !important;
  }
  ion-input {
    margin-top: 15px;
    border: 1px solid #ddd;
    padding: 10px;
    max-width: 300px;
    input {
      padding: 10px !important;
    }
  }
}
